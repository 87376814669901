import React from 'react';

import Icon from '../../../styles/atoms/icons';

import Image from '../../../utils/OptimizedImage';

const HeroVideo = ({ component, playVideo }) => (
  <div className="video-container">
    {component?.videoThumbnail?.image?.gatsbyImageData ? (
      <Image
        image={component?.videoThumbnail?.image?.gatsbyImageData}
        alt="YouTube Video Thumbnail"
      />
    ) : (
      <Image
        src={component?.videoThumbnail?.image?.url}
        alt="YouTube Video Thumbnail"
      />
    )}
    <div className="overlay">
      <div className="watch-video-btn">
        <span onClick={playVideo}>
          <Icon id="FaPlay" />
        </span>
      </div>
    </div>
  </div>
);

export default HeroVideo;
